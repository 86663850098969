// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";

import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration

const firebaseConfig = {

  apiKey: "AIzaSyC1fOmO_UC9p0ZI-v2D2PmrZ6xzck8DDXg",

  authDomain: "rimi-my-monitoring.firebaseapp.com",

  projectId: "rimi-my-monitoring",

  storageBucket: "rimi-my-monitoring.appspot.com",

  messagingSenderId: "697240983437",

  appId: "1:697240983437:web:8bd5937842446b28cdcfc4"

};


// Initialize Firebase

const app = initializeApp(firebaseConfig);

export default getFirestore(app);