import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import store from '@/store/store'
import VueEllipseProgress from "vue-ellipse-progress";
import { VueMaskDirective } from 'v-mask'


Vue.directive('mask', VueMaskDirective);
var filter = function(text, length, clamp){
  clamp = clamp || '...';
  var node = document.createElement('div');
  node.innerHTML = text;
  var content = node.textContent;
  return content.length > length ? content.slice(0, length) + clamp : content;
};

Vue.filter('truncate', filter);
let app = null;

Vue.use(VueEllipseProgress);

app = new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')


