<template>
  <div class="login container">
    <v-snackbar v-model="$store.state.snackbarTrigger" :timeout="3000">
      {{ $store.state.snackbarMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="$store.commit('setSnackbarTrigger', false)"
        >
          Ok
        </v-btn>
      </template>
    </v-snackbar>

    <v-card class="rounded-xl">
      <v-form ref="form" v-model="valid" @submit.prevent="login">
        <v-row align="center" justify="center" class="pb-3">
          <v-card
            color="transparent"
            elevation="7"
            class="mr-2 mt-4 rounded-xl"
          >
            <h3 align="center" class="mx-4 my-2 blue--text text--darken-1">
              Bejelentkezés
            </h3>
          </v-card>
        </v-row>

        <!-- Username -->
        <v-text-field
          v-model="username"
          label="Felhasználónév"
          required
          :rules="simpleRules"
          solo
          autocomplete="username"
          class="px-8 pb-1 pt-7"
        ></v-text-field>

        <!-- JELSZÓ -->
        <v-text-field
          v-model="password"
          label="Jelszó"
          required
          autocomplete="current-password"
          @click:append="showPassword = !showPassword"
          :rules="passwordRules"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPassword ? 'text' : 'password'"
          solo
          class="px-8"
        ></v-text-field>

        <!-- Login button -->
        <div class="pb-4" align="center">
          <!-- <invisible-recaptcha sitekey="6Lczf2sdAAAAAOkFv9dDyCHHvwpKeIkpi8esPenO" :validate='validate' :callback="login2" class="btn btn-danger" type="submit" :disabled="!valid"> -->
          <v-btn
            @click.prevent="login"
            type="submit"
            v-if="isLoading == false"
            v-ripple
            :disabled="!valid"
            >Ok
            <v-icon> mdi-login </v-icon>
          </v-btn>
          <vue-ellipse-progress
            v-if="isLoading"
            :progress="progress"
            :size="30"
            :thickness="2"
            :loading="true"
            :noData="false"
          >
          </vue-ellipse-progress>
          <!-- </invisible-recaptcha> -->
        </div>
        <!-- Feedback -->
        <h4 align="center" class="mb-3 red--text" v-if="feedback">
          {{ feedback }}
        </h4>
      </v-form>
    </v-card>
    <!-- <div align="center" class="mt-4" id="recaptcha"></div> -->
  </div>
</template>

<script>
import axios from "axios";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
      valid: true,
      showPassword: false,
      reCaptcha: false,
      feedback: "",
      menu: "user_data",
      menu_options: ["user_data", "32hours", "32days", "email"],
      simpleRules: [(v) => !!v || "A mező kitöltése kötelező"],
      passwordRules: [
        (v) => !!v || "A mező kitöltése kötelező",
        (v) =>
          (v && v.length >= 8) ||
          "A jelszónak legalább 8 karakternek kell lennie",
      ],
      isLoading: false,
      progress: 0,
    };
  },
  components: {
    // "invisible-recaptcha": InvisibleRecaptcha
  },
  methods: {
    login() {
    this.isLoading = true; // Start loading
    if (this.username && this.password) {
      axios.get(process.env.VUE_APP_API_URL + "/my_monitoring_api.php?eventType=login&username=" + this.username + "&password=" + this.password, { withCredentials: true })
        .then((response) => {
          if (typeof response.data != "string") {
            this.$store.state.user = response.data[0];
            const auth = getAuth();
            signInWithEmailAndPassword(auth, "postman@rimi.hu", this.$store.state.user.POSTMANTOKEN)
              .then((cred) => {
                this.$router.push({ path: "/" });
              })
              .catch((err) => {
                console.log(err);
                this.feedback = err.message;
                this.isLoading = false; // Stop loading on error
                this.$router.push({ path: "/login" });
              });
          } else {
            this.username = "";
            this.password = "";
            this.isLoading = false; // Stop loading on error
            this.$store.commit("setSnackbarMessage", "Sikertelen bejelentkezés :(");
            this.$store.commit("setSnackbarTimeout", 3000);
            this.$store.commit("setSnackbarTrigger", true);
          }
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false; // Stop loading on error
        });
    } else if (!this.reCaptcha) {
      this.feedback = "reCaptcha hitelesítés sikertelen!";
      this.isLoading = false; // Stop loading
    }
  },
    validate() {
      this.isLoading = true;
    },
  },
  mounted() {
    this.$store.state.alarmStatus = "";
    clearInterval(this.$store.state.alarmTimer);
    this.valid = false;
  },
};
</script>

<style>
.login {
  max-width: 400px;
  margin-top: 60px;
}
.login h2 {
  font-size: 2.4em;
}
.login .field {
  margin-bottom: 16px;
}
</style>
