<template>
<v-container fluid>
    <!-- Dekstop -->
      <v-card class="mb-1 mx-1 rounded-xl" v-if="!isMobile" color="grey lighten-3" @click.prevent="jumpInHistory">
        <v-container fluid class="d-flex" fill-height>

        <v-row class="d-flex" align="center" justify="center">
          <v-col class="d-flex align-center justify-center" cols="1">
                <h4>
              {{ userEvent.ACCOUNT }}
            </h4>            
          </v-col>
          <v-col class="d-flex align-center justify-center" cols="1">
            <h4>
              {{ userEvent.LINE }}
            </h4>
          </v-col>
          <v-col class="d-flex align-center justify-center" cols="2">
            <h4>
              {{ userEvent.IDO }}
            </h4>
          </v-col>
          <v-col class="d-flex align-center justify-center" cols="7">
            <h4>
              {{ userEvent.LEIRAS }}
            </h4>
          </v-col>
        </v-row>
      </v-container>
      </v-card>
    <!-- Mobile -->
      <v-card class="mb-1 rounded-xl" height="90" v-if="isMobile" color="grey lighten-3" @click.prevent="jumpInHistory">
        <v-container fluid class="d-flex" fill-height>
        <v-row class="d-flex" align="center" justify="center">
          <v-col class="d-flex align-center justify-center" cols="3">
            <h5>
              {{ userEvent.IDO }}
            </h5>
          </v-col>
          <v-col class="d-flex align-center justify-center" cols="8">
            <h5>
              {{ userEvent.LEIRAS }}
            </h5>
          </v-col>
        </v-row>
      </v-container>
      </v-card>

      </v-container>
</template>

<script>
import { isMobile } from "mobile-device-detect";

export default {
  name: "EventCard",
  props: ["userEvent"],
  data() {
    return {
        isMobile: isMobile,
    };
  },
  methods: {
    jumpInHistory(){
      this.$store.state.event = {}
      this.$store.state.event = this.userEvent
    }
  },

  components: {},
};
</script>

<style></style>
