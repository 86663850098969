<template>
  <v-app>
    <!-- Connect to server overlay -->
    <v-overlay :value="$store.state.wsOverlay" class="my-overlay">
      <v-row class="d-flex align-center justify-cente" align="center" justify="center">
        <v-col class="d-flex align-center justify-center" cols="12">
          <v-card class="rounded-xl" color="grey darken-1">
            <h3 class="mx-2 my-1">Adatok lekérése...</h3>
          </v-card>
        </v-col>
      </v-row>
    </v-overlay>
    <!-- On Dialog -->
    <v-dialog v-model="onDialog" max-width="350" class="onDialog">
      <v-card class="onCard">
        <v-card-title class="headline"> Riasztó bekapcsolása </v-card-title>
        <v-card-text> Biztosan be szeretné kapcsolni a riasztót? </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="setSmsInProgress">
            Igen
          </v-btn>
          <v-btn color="cyan darken-2" text @click="onDialog = false">
            Nem
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Off Dialog -->
    <v-dialog v-model="offDialog" max-width="350" class="offDialog">
      <v-card class="offCard">
        <v-card-title class="headline"> Riasztó kikapcsolása </v-card-title>
        <v-card-text> Biztosan ki szeretné kapcsolni a riasztót? </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="setSmsInProgress">
            Igen
          </v-btn>
          <v-btn color="cyan darken-2" text @click="offDialog = false">
            Nem
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <nav>
      <!-- Appbar -->
      <v-app-bar v-if="$route.name != 'Login' && this.$store.state.user" app :height="isMobile ? '50' : '85'" class="app_bar">
        <v-row align="center" justify="space-between">
          <v-col class="d-flex align-center justify-start">
            <v-flex fill-height class="d-flex align-center">
              <img src="./assets/rimi2.png" class="mr-4" />
              <h5 class="center">My Monitoring</h5>
            </v-flex>
          </v-col>

          <v-col class="d-flex align-center justify-center" v-if="!isMobile">
            <v-btn height="85" depressed width="80" @click="changePage('data')">
              <v-row align="center" justify="center" class="d-flex my-4">
                <v-col>
                  <v-icon class="mb-2" size="30" color="blue">
                    mdi-account-circle
                  </v-icon>
                  <h5>Adatok</h5>
                </v-col>
              </v-row>
            </v-btn>
            <v-btn height="85" depressed width="80" @click="changePage('events1')">
              <v-row align="center" justify="center" class="d-flex">
                <v-col class="align-center justify-center">
                  <v-icon class="mb-2" size="30" color="blue">
                    mdi-clock
                  </v-icon>
                  <h5>24 óra</h5>
                </v-col>
              </v-row>
            </v-btn>
            <v-btn height="85" depressed width="80" @click="changePage('events30')">
              <v-row align="center" justify="center" class="d-flex">
                <v-col>
                  <v-icon class="mb-2" size="30" color="blue">
                    mdi-calendar
                  </v-icon>
                  <h5>30 nap</h5>
                </v-col>
              </v-row>
            </v-btn>
            <v-btn height="85" depressed width="80" @click="changePage('receipts')">
              <v-row align="center" justify="center" class="d-flex">
                <v-col>
                  <v-icon class="mb-2" size="30" color="blue">
                    mdi-receipt-text
                  </v-icon>
                  <h5>Számlák</h5>
                </v-col>
              </v-row>
            </v-btn>
            <v-btn height="85" depressed width="80" @click="changePage('email')">
              <v-row align="center" justify="center" class="d-flex">
                <v-col>
                  <v-icon size="30" color="blue" class="mb-2">
                    mdi-mail
                  </v-icon>
                  <h5>E-mail</h5>
                </v-col>
              </v-row>
            </v-btn>
            <v-btn :disabled="!this.$store.state.user.SMSNUM" height="85" depressed width="110" @click="() => {
              if (this.$store.state.alarm) {
                offDialog = true;
              } else if (!this.$store.state.alarm) {
                onDialog = true;
              }
            }
              ">
              <v-row align="center" justify="center">
                <v-col>
                  <v-icon size="30" color="blue" class="mb-2" v-if="!$store.state.alarm">
                    mdi-lock-open
                  </v-icon>
                  <v-icon size="30" color="blue" class="mb-2" v-if="$store.state.alarm">
                    mdi-lock
                  </v-icon>
                  <h5 v-if="!$store.state.alarm">Bekapcsolás</h5>
                  <h5 v-if="$store.state.alarm">Kikapcsolás</h5>
                </v-col>
              </v-row>
            </v-btn>
          </v-col>
          <v-col class="d-flex align-center justify-end" v-if="!isMobile">
            <v-btn small fab class="mr-6" @click="logout" :width="isMobile ? '30' : '42'"
              :height="isMobile ? '30' : '42'">
              <v-icon color="blue" :size="isMobile ? '18' : '23'">
                mdi-logout
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-app-bar>
      <v-main>
        <!-- Line, account and Status -->
        <v-row :class="{
          'mb-n8 d-flex': $store.state.status,
          'mb-6 d-flex ': !$store.state.status,
        }" justify="center" v-if="$store.state.user">
          <v-container fluid fill-height>
            <v-col v-bind:class="{
              'd-flex align-center justify-cnter mt-2': isMobile,
              'd-flex align-center justify-center mt-2 mx-2': !isMobile,
            }">
              <!-- User ID -->
              <v-btn :block="isMobile" outlined rounded height="90" :width="getWidth()" color="grey darken-4"
                @click.prevent="toggleStatus" :ripple="false">
                <v-col cols="5">
                  <h6 v-if="isMobile" class="mt-n1 mb-n1">Vonal/Rádiószám</h6>
                  <h5 v-if="!isMobile" class="mt-n1 mb-n1">
                    Vonalszám-Rádiószám
                  </h5>
                  <br />
                  <h4 class="mb-1" v-if="$store.state.user">
                    {{
                      $store.state.user.LINE + "-" + $store.state.user.ACCOUNT
                    }}
                  </h4>
                </v-col>
                <v-divider vertical class="my-4"></v-divider>
                <v-col cols="7" class="mr-2">
                  <h6 v-if="isMobile" class="mb-n4">Riasztó státusz</h6>
                  <h5 v-if="!isMobile" class="mb-n4">Riasztó státusz</h5>
                  <br />
                  <v-row class="d-flex" justify="space-between">
                    <v-col class="d-flex align-center justify-space-between mb-n5 mt-2" v-if="sortedEvents.length">
                      <h5 v-if="$store.state.alarm" class="mr-2">Zárt</h5>
                      <h5 v-else-if="!$store.state.alarm" class="mr-2">
                        Nyitott
                      </h5>
                      <!-- Status indicator positive=green negative=red intermediary=yellow-->
                      <status-indicator :status="$store.state.alarm ? 'negative' : 'positive'" />
                    </v-col>
                    <v-col class="d-flex align-center justify-space-between mb-n5 mt-2" v-else>
                      <h5>Lekérdezés...</h5>
                    </v-col>
                  </v-row>
                  <v-row align="center" justify="space-between">
                    <v-col class="d-flex align-center justify-space-between mb-n4" v-if="sortedEvents.length">
                      <h5 v-if="$store.state.alarmStatus == 'pulse'" class="mr-2">
                        Riasztás
                      </h5>
                      <h5 v-else class="mr-2">Nincs riasztás</h5>

                      <!-- Status indicator positive=green negative=red intermediary=yellow-->
                      <status-indicator :status="$store.state.alarmPulseColor"
                        :pulse="$store.state.alarmStatus === 'pulse'" />
                    </v-col>
                    <v-col class="d-flex align-center justify-space-between mb-n4" v-else>
                      <h5>Lekérdezés...</h5>
                    </v-col>
                  </v-row>
                  <v-row align="center" justify="space-between">
                    <!-- Error indicator -->
                    <v-col class="d-flex align-center justify-space-between mt-n1" v-if="sortedEvents.length">
                      <h5 v-if="$store.state.errorStatusCode == '03'" class="mr-2">
                        Akku Hiba
                      </h5>
                      <h5 v-else-if="$store.state.errorStatusCode == '04'" class="mr-2">
                        Akku Vissza
                      </h5>
                      <h5 v-else-if="$store.state.errorStatusCode == '07'" class="mr-2">
                        AC Hiba
                      </h5>
                      <h5 v-else-if="$store.state.errorStatusCode == '08'" class="mr-2">
                        AC Vissza
                      </h5>
                      <h5 v-else-if="$store.state.errorStatusCode == '13'" class="mr-2">
                        Egyéb Hiba
                      </h5>
                      <h5 v-else-if="$store.state.errorStatusCode == '16'" class="mr-2">
                        E.Hiba Vissza
                      </h5>
                      <h5 v-else-if="$store.state.errorStatusCode == '14'" class="mr-2">
                        Komm. Hiba
                      </h5>
                      <h5 v-else-if="$store.state.errorStatusCode == '15'" class="mr-2">
                        Komm.H.Vissza
                      </h5>
                      <h5 v-else class="mr-2">Nincs hiba</h5>
                      <!-- Status indicator positive=green negative=red intermediary=yellow-->
                      <status-indicator :status="$store.state.errorStatusCode == '03'
                        ? 'intermediary'
                        : $store.state.errorStatusCode == '07'
                          ? 'intermediary'
                          : $store.state.errorStatusCode == '13'
                            ? 'intermediary'
                            : $store.state.errorStatusCode == '14'
                              ? 'intermediary'
                              : ''
                        " />
                    </v-col>
                    <v-col class="d-flex align-center justify-space-between mt-n1" v-else>
                      <h5>Lekérdezés...</h5>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col class="align-center justify-center mt-2">
                  <v-btn id="no-background-hover" depressed color="transparent" :ripple="false" small fab width="25"
                    height="25">
                    <v-icon size="18" color="grey darken-4" v-if="!$store.state.status">
                      mdi-arrow-down
                    </v-icon>
                    <v-icon size="18" color="grey darken-4" v-if="$store.state.status">
                      mdi-arrow-up
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-btn>
            </v-col>
          </v-container>
        </v-row>
        <v-row class="mb-n8" align="center" justify="space-around" v-if="$store.state.user">
          <!-- Last Status -->
          <v-col class="lastStatus d-flex align-center justify-space-around mt-n2" v-if="$store.state.status">
            <v-btn outlined rounded color="grey darken-4" :ripple="false">
              <v-col class="align-center justify-center">
                <h5 class="mt-n1 mb-n4">Utolsó jelzés</h5>
                <br />
                <v-col class="d-flex mt-n2 mb-n8 d-flex align-center justify-center" v-if="lastEvent.IDO">
                  {{ lastEvent.IDO }}
                </v-col>

                <v-col v-else>
                  <h5>Lekérdezés...</h5>
                </v-col>
                <br />
                <v-col class="mb-n3 d-flex align-center justify-center" v-if="sortedEvents">
                  <div class="" style="
                      width: 280px;
                      word-break: break-word;
                      white-space: nowrap;
                    ">
                    <h4 class="text-center text-wrap center d-flex align-center justify-center grey--text text--darken-4"
                      v-if="lastEvent.LEIRAS">
                      {{ lastEvent.LEIRAS }}
                    </h4>
                    <h4 class="text-center text-wrap center d-flex align-center justify-center grey--text text--darken-4"
                      v-else>
                      Lekérdezés...
                    </h4>
                  </div>
                </v-col>
              </v-col>
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="mb-n12" align="center" justify="space-around" v-if="$store.state.user">
          <!-- 16 Zones -->
          <v-col class="d-flex align-center justify-space-around mt-1" v-if="$store.state.status">
            <!-- User ID -->
            <v-btn outlined rounded height="80" color="grey darken-4" :ripple="false">
              <v-col>
                <h5 class="mt-n1 mb-n4">Zóna státusz</h5>
                <br />
                <v-col class="mt-n2 mb-n8 d-flex align-center justify-center" v-if="sortedEvents">
                  <!-- 1 -->
                  <v-card :color="$store.state.zones[0] == '1'
                    ? 'red darken-2'
                    : $store.state.zones[0] == '0'
                      ? 'green darken-2'
                      : 'green darken-2'
                    " class="rounded-xl ml-n6 mr-1" height="20px" width="20px">
                    <h5 class="mt-1 white--text text--darken-4">1</h5>
                  </v-card>
                  <!-- 2 -->
                  <v-card :color="$store.state.zones[1] == '1'
                    ? 'red darken-2'
                    : $store.state.zones[1] == '0'
                      ? 'green darken-2'
                      : 'green darken-2'
                    " class="rounded-xl mr-1" height="20px" width="20px">
                    <h5 class="mt-1 white--text text--darken-4">2</h5>
                  </v-card>
                  <!-- 3 -->
                  <v-card :color="$store.state.zones[2] == '1'
                    ? 'red darken-2'
                    : $store.state.zones[2] == '0'
                      ? 'green darken-2'
                      : 'green darken-2'
                    " class="rounded-xl mr-1" height="20px" width="20px">
                    <h5 class="mt-1 white--text text--darken-4">3</h5>
                  </v-card>
                  <!-- 4 -->
                  <v-card :color="$store.state.zones[3] == '1'
                    ? 'red darken-2'
                    : $store.state.zones[3] == '0'
                      ? 'green darken-2'
                      : 'green darken-2'
                    " class="rounded-xl mr-1" height="20px" width="20px">
                    <h5 class="mt-1 white--text text--darken-4">4</h5>
                  </v-card>
                  <!-- 5 -->
                  <v-card :color="$store.state.zones[4] == '1'
                    ? 'red darken-2'
                    : $store.state.zones[4] == '0'
                      ? 'green darken-2'
                      : 'green darken-2'
                    " class="rounded-xl mr-1" height="20px" width="20px">
                    <h5 class="mt-1 white--text text--darken-4">5</h5>
                  </v-card>
                  <!-- 6 -->
                  <v-card :color="$store.state.zones[5] == '1'
                    ? 'red darken-2'
                    : $store.state.zones[5] == '0'
                      ? 'green darken-2'
                      : 'green darken-2'
                    " class="rounded-xl mr-1" height="20px" width="20px">
                    <h5 class="mt-1 white--text text--darken-4">6</h5>
                  </v-card>
                  <!-- 7 -->
                  <v-card :color="$store.state.zones[6] == '1'
                    ? 'red darken-2'
                    : $store.state.zones[6] == '0'
                      ? 'green darken-2'
                      : 'green darken-2'
                    " class="rounded-xl mr-1" height="20px" width="20px">
                    <h5 class="mt-1 white--text text--darken-4">7</h5>
                  </v-card>
                  <!-- 8 -->
                  <v-card :color="$store.state.zones[7] == '1'
                    ? 'red darken-2'
                    : $store.state.zones[7] == '0'
                      ? 'green darken-2'
                      : 'green darken-2'
                    " class="rounded-xl mr-n6" height="20px" width="20px">
                    <h5 class="mt-1 white--text text--darken-4">8</h5>
                  </v-card>
                </v-col>
                <br />
                <v-col class="d-flex align-center justify-center mb-n3" v-if="sortedEvents">
                  <!-- 9 -->
                  <v-card :color="$store.state.zones[8] == '1'
                    ? 'red darken-2'
                    : $store.state.zones[8] == '0'
                      ? 'green darken-2'
                      : 'green darken-2'
                    " class="rounded-xl ml-n6 mr-1" height="20px" width="20px">
                    <h5 class="mt-1 white--text text--darken-4">9</h5>
                  </v-card>
                  <!-- 10 -->
                  <v-card :color="$store.state.zones[9] == '1'
                    ? 'red darken-2'
                    : $store.state.zones[9] == '0'
                      ? 'green darken-2'
                      : 'green darken-2'
                    " class="rounded-xl mr-1" height="20px" width="20px">
                    <h5 class="mt-1 white--text text--darken-4">10</h5>
                  </v-card>
                  <!-- 11 -->
                  <v-card :color="$store.state.zones[10] == '1'
                    ? 'red darken-2'
                    : $store.state.zones[10] == '0'
                      ? 'green darken-2'
                      : 'green darken-2'
                    " class="rounded-xl mr-1" height="20px" width="20px">
                    <h5 class="mt-1 white--text text--darken-4">11</h5>
                  </v-card>
                  <!-- 12 -->
                  <v-card :color="$store.state.zones[11] == '1'
                    ? 'red darken-2'
                    : $store.state.zones[11] == '0'
                      ? 'green darken-2'
                      : 'green darken-2'
                    " class="rounded-xl mr-1" height="20px" width="20px">
                    <h5 class="mt-1 white--text text--darken-4">12</h5>
                  </v-card>
                  <!-- 13 -->
                  <v-card :color="$store.state.zones[12] == '1'
                    ? 'red darken-2'
                    : $store.state.zones[12] == '0'
                      ? 'green darken-2'
                      : 'green darken-2'
                    " class="rounded-xl mr-1" height="20px" width="20px">
                    <h5 class="mt-1 white--text text--darken-4">13</h5>
                  </v-card>
                  <!-- 14 -->
                  <v-card :color="$store.state.zones[13] == '1'
                    ? 'red darken-2'
                    : $store.state.zones[13] == '0'
                      ? 'green darken-2'
                      : 'green darken-2'
                    " class="rounded-xl mr-1" height="20px" width="20px">
                    <h5 class="mt-1 white--text text--darken-4">14</h5>
                  </v-card>
                  <!-- 15 -->
                  <v-card :color="$store.state.zones[14] == '1'
                    ? 'red darken-2'
                    : $store.state.zones[14] == '0'
                      ? 'green darken-2'
                      : 'green darken-2'
                    " class="rounded-xl mr-1" height="20px" width="20px">
                    <h5 class="mt-1 white--text text--darken-4">15</h5>
                  </v-card>
                  <!-- 16 -->
                  <v-card :color="$store.state.zones[15] == '1'
                    ? 'red darken-2'
                    : $store.state.zones[15] == '0'
                      ? 'green darken-2'
                      : 'green darken-2'
                    " class="rounded-xl mr-n6" height="20px" width="20px">
                    <h5 class="mt-1 white--text text--darken-4">16</h5>
                  </v-card>
                </v-col>
                <v-col v-else>
                  <h5>Lekérdezés...</h5>
                </v-col>
              </v-col>
            </v-btn>
          </v-col>
        </v-row>
        <router-view></router-view>
      </v-main>
    </nav>
  </v-app>
</template>

<script>
import { isMobile } from "mobile-device-detect";
import { StatusIndicator } from "vue-status-indicator";
import axios from "axios";
import db from "@/firebase/init.js";
import { collection, addDoc, getDocs } from "firebase/firestore";
import HomePage from "@/components/home/HomePage";

export default {
  name: "Navbar",
  components: {
    StatusIndicator,
    HomePage,
  },
  data() {
    return {
      lastEvent: [],
      drawer: false,
      offDialog: false,
      onDialog: false,
      filterStatus: [],
      checked: false,
      checkbox: false,
      activeFilters: [],
      searchField: null,
      isMobile: isMobile,
      alarmTimer: null,
      alarmTimer2: null,
      permanentTimer: null,
      smsTimer: null,
      events: [],
      testCodes: [17, 18, 19],
      openCloseCodes: [5, 6],
      admin_items: [
        { title: "Felhasználó regisztrálás" },
        { title: "Archív munkalapok" },
      ],
      items: [{ title: "Archív munkalapok" }],
    };
  },

  mounted() {
    this.eventAnalizer()
  },
  methods: {
    getWidth() {
      if (isMobile) {
        return "0";
      } else {
        return "600";
      }
    },
    async alarmDisarm() {
      if (this.$store.state.user.SMSNUM) {
        try {
          const docRef = await addDoc(collection(db, "sms"), {
            to: this.$store.state.user.SMSNUM,
            channelId: this.$store.state.user.CHANNELID,
            type: "text",
            content: {
              text: this.$store.state.user.SMSPIN,
            },
          });
          //Snackbar here
          if (this.$store.state.alarm) {
            this.$store.commit(
              "setSnackbarMessage",
              "Kikapcsolás folyamatban!"
            );
          }
          if (!this.$store.state.alarm) {
            this.$store.commit(
              "setSnackbarMessage",
              "Bekapcsolás folyamatban!"
            );
          }
          this.alarmTimer2 = setInterval(() => {
            if (this.$store.state.user) {
              this.getEvents("all");
            }
          }, 5000);
          this.smsTimer = setTimeout(() => {
            this.$store.state.smsInProgress = false;
            // clearInterval(this.alarmTimer2)
          }, 120000);
          this.$store.commit("setSnackbarTimeout", 3000);
          this.$store.commit("setSnackbarTrigger", true);
        } catch (e) {
          this.$store.state.smsInProgress = false;
          console.error("Error adding document: ", e);
        }
      } else {
        this.$store.commit(
          "setSnackbarMessage",
          "Nincs ismert riasztó telefonszám"
        );
      }
    },
    toggleStatus() {
      this.$store.state.status = !this.$store.state.status;
      if (this.$store.state.status) {
        if (!this.isMobile) {
          this.$store.state.desktopScrollHeight =
            parseInt(this.$store.state.desktopScrollHeight) - 200;
        } else {
          this.$store.state.mobileScrollHeight =
            parseInt(this.$store.state.mobileScrollHeight) - 200;
        }
      }
      if (!this.$store.state.status) {
        if (!this.isMobile) {
          this.$store.state.desktopScrollHeight =
            parseInt(this.$store.state.desktopScrollHeight) + 200;
        } else {
          this.$store.state.mobileScrollHeight =
            parseInt(this.$store.state.mobileScrollHeight) + 200;
        }
      }
    },
    setSmsInProgress() {
      this.offDialog = false;
      this.onDialog = false;
      if (!this.$store.state.smsInProgress) {
        this.$store.state.smsInProgress = true;
      } else {
        this.$store.commit("setSnackbarTrigger", false);
        setTimeout(
          () =>
            this.delayedNotification(
              "A be/ki riasztás már folyamatban van!",
              3000,
              true
            ),
          100
        );
      }
    },
    getEvents(days) {
      this.$store.state.triggerGetEvents = !this.$store.state.triggerGetEvents
      if (days == 1 && this.$store.state.user) {
        // Get ONAPLO events for the last 24 hours
        axios.get(process.env.VUE_APP_API_URL + "/my_monitoring_api.php", {
          params: {
            uid: this.$store.state.user.TOKEN,
            eventType: 'getEventsOnaplo',
            account: this.$store.state.user.ACCOUNT,
            line: this.$store.state.user.LINE
          },
          withCredentials: true
        })
          .then(response => {
            if (typeof response.data !== "string") {
              this.$store.state.events = response.data;
            } else {
              this.$store.state.events = [];
            }
          })
          .catch((error) => {
            console.log(error);
            //Snackbar here
            this.$store.commit("setSnackbarTrigger", false);
            setTimeout(
              () =>
                this.delayedNotification(
                  "Adatbázis kapcsolat sikertelen:(",
                  3000,
                  true
                ),
              100
            );
          });
      } else if (days == "all" && this.$store.state.user) {
        let _eventBuffer = [];
        // Get ONAPLO events for the last 24 hours
        axios.get(process.env.VUE_APP_API_URL + "/my_monitoring_api.php", {
          params: {
            uid: this.$store.state.user.TOKEN,
            eventType: 'getEventsOnaplo',
            account: this.$store.state.user.ACCOUNT,
            line: this.$store.state.user.LINE
          },
          withCredentials: true
        })
          .then(response => {
            if (typeof response.data !== "string") {
              _eventBuffer = response.data;
            } else {
              _eventBuffer = [];
            }

            // Get all events for account
            return axios.get(process.env.VUE_APP_API_URL + "/my_monitoring_api.php", {
              params: {
                uid: this.$store.state.user.TOKEN,
                eventType: 'getEvents',
                account: this.$store.state.user.ACCOUNT,
                line: this.$store.state.user.LINE,
                date: this.getDateXDaysAgo(365)
              },
              withCredentials: true
            });
          })
          .then(response => {
            if (typeof response.data !== "string") {
              response.data.forEach(data => {
                _eventBuffer.push(data);
              });
            }
            this.$store.state.events = _eventBuffer;
          })
          .catch(error => {
            console.error(error);
            // Handle error, e.g., show snackbar
            this.$store.commit("setSnackbarTrigger", false);
            setTimeout(() => this.delayedNotification("Adatbázis kapcsolat sikertelen:(", 3000, true), 100);
          });
      } else if (this.$store.state.user) {
        // Get all events for account
        axios.get(process.env.VUE_APP_API_URL + "/my_monitoring_api.php", {
          params: {
            uid: this.$store.state.user.TOKEN,
            eventType: 'getEvents',
            account: this.$store.state.user.ACCOUNT,
            line: this.$store.state.user.LINE,
            date: this.getDateXDaysAgo(days)
          },
          withCredentials: true
        })
          .then(response => {
            if (typeof response.data !== "string") {
              this.$store.state.events = response.data;
            } else {
              this.$store.state.events = [];
            }
          })
          .catch((error) => {
            console.log(error);
            //Snackbar here
            this.$store.commit("setSnackbarTrigger", false);
            setTimeout(
              () =>
                this.delayedNotification(
                  "Adatbázis kapcsolat sikertelen:(",
                  3000,
                  true
                ),
              100
            );
          });
      }
    },
    delayedNotification(message, timeout, bool) {
      this.$store.commit("setSnackbarMessage", message);
      this.$store.commit("setSnackbarTimeout", timeout);
      this.$store.commit("setSnackbarTrigger", bool);
    },
    hex2bin(data) {
      let bin = data
        .split("")
        .map((i) => parseInt(i, 16).toString(2).padStart(4, "0"))
        .join("");
      return bin;
    },
    getDateXDaysAgo(numOfDays, date = new Date()) {
      const daysAgo = new Date(date.getTime());
      daysAgo.setDate(date.getDate() - numOfDays);
      let dateWithoutHours =
        daysAgo.getFullYear().toString() +
        "-" +
        (daysAgo.getMonth() + 1).toString() +
        "-" +
        daysAgo.getDate().toString();
      return dateWithoutHours;
    },
    logout() {
      axios.get(process.env.VUE_APP_API_URL + '/logout.php', { withCredentials: true })
        .then(response => {
          this.$store.commit("setUser", null);
          this.$router.push('/login');
        })
        .catch(error => {
          console.error('Logout failed:', error);
          // Handle errors (if any)
        });

    },
    changePage(page) {
      this.$store.state.currentPage = page;
      // if (page == "events1" || page == "events30") {
      //   if (!this.$store.state.interval) {
      //     this.$store.state.interval = setInterval(() => {
      //       if (this.$store.state.user) {
      //         this.getEvents("all");
      //       }
      //     }, 30000);
      //   }
      // }
    },

    runSearchFilter() {
      if (this.searchField.length) {
        this.$store.commit("setSearchFilter", this.searchField.toLowerCase());
      } else {
        this.$store.commit("setSearchFilter", "");
      }
    },
    goTo(ref) {
      if (ref == "Felhasználó regisztrálás") {
        this.$router.push("Signup");
      } else if (ref == "Archív munkalapok") {
        this.$router.push("ArchivePage");
      }
    },

    eventAnalizer(val, auto) {
      // Find last event which is not a test signal
      let foundEvent = false
      if (val) {
        val.every((alarmEvent) => {
          if (!this.testCodes.includes(parseInt(alarmEvent.JLEIRAS[30] + alarmEvent.JLEIRAS[31], 16))) {
            this.lastEvent = alarmEvent;
            foundEvent = true
            return false;
          }
          return true;
        })
        if (!foundEvent) {
          this.lastEvent = val.slice(-1)[0]
        }

        // SET ERROR
        val.every((alarmEvent) => {
          if (
            parseInt(alarmEvent.JLEIRAS[30] + alarmEvent.JLEIRAS[31], 16) ==
            "3"
          ) {
            this.$store.state.errorStatusCode = "03";
            return false;
          } else if (
            parseInt(alarmEvent.JLEIRAS[30] + alarmEvent.JLEIRAS[31], 16) ==
            "4"
          ) {
            this.$store.state.errorStatusCode = "04";
            return false;
          } else if (
            parseInt(alarmEvent.JLEIRAS[30] + alarmEvent.JLEIRAS[31], 16) ==
            "7"
          ) {
            this.$store.state.errorStatusCode = "07";
            return false;
          } else if (
            parseInt(alarmEvent.JLEIRAS[30] + alarmEvent.JLEIRAS[31], 16) ==
            "8"
          ) {
            this.$store.state.errorStatusCode = "08";
            return false;
          } else if (
            parseInt(alarmEvent.JLEIRAS[30] + alarmEvent.JLEIRAS[31], 16) ==
            "13"
          ) {
            this.$store.state.errorStatusCode = "13";
            return false;
          } else if (
            parseInt(alarmEvent.JLEIRAS[30] + alarmEvent.JLEIRAS[31], 16) ==
            "14"
          ) {
            this.$store.state.errorStatusCode = "14";
            return false;
          } else if (
            parseInt(alarmEvent.JLEIRAS[30] + alarmEvent.JLEIRAS[31], 16) ==
            "15"
          ) {
            this.$store.state.errorStatusCode = "15";
            return false;
          } else if (
            parseInt(alarmEvent.JLEIRAS[30] + alarmEvent.JLEIRAS[31], 16) ==
            "16"
          ) {
            this.$store.state.errorStatusCode = "16";
            return false;
          }
          return true;
        });

        // SET ZONES
        if (!this.openCloseCodes.includes(parseInt(this.lastEvent.JLEIRAS[30] + this.lastEvent.JLEIRAS[31], 16))) {
          this.lastEvent.AKTALLAPOT.split("").every((zoneStatus, index) => {
            this.$store.state.zones[index] = zoneStatus;
            return true;
          });
        }
        else {
          this.$store.state.zones.every((zone, index) => {
            this.$store.state.zones[index] = "0"
          })
        }

        // SET OPEN/CLOSE
        if (this.lastEvent.AKTSTATUS[2] == "1") {
          this.$store.state.alarm = true;
        } else {
          this.$store.state.alarm = false;
        }
        // SET ALARM
        this.$store.state.zones.every((zone) => {
          if (zone == "1" && !this.openCloseCodes.includes(parseInt(this.lastEvent.JLEIRAS[30] + this.lastEvent.JLEIRAS[31], 16))) {
            this.$store.state.alarmStatus = "pulse";
            clearInterval(this.alarmTimer);
            this.alarmTimer = null;
            this.alarmTimer = setInterval(() => {
              if (!this.$store.state.alarmPulseColor.length) {
                this.$store.state.alarmPulseColor = "negative";
              } else {
                this.$store.state.alarmPulseColor = "";
              }
            }, 1000);
            return false;
          } else {
            return true;
          }
        });
      }
    },
  },

  computed: {
    sortedEvents: {
      get: function () {
        return this.$store.state.events.sort((a, b) => {
          if (a.IDO < b.IDO) {
            return 1;
          }
          if (a.IDO > b.IDO) {
            return -1;
          }
          return 0;
        });
      },
    },
  },

  watch: {
    sortedEvents: function (val) {
      this.eventAnalizer(val, true);
    },
    "$store.state.user": function (val) {
      this.getEvents("all");
    },
    "$store.state.event": function (val) {
      // Clear all timers and alarms
      clearInterval(this.$store.state.interval);
      this.$store.state.interval = null;
      clearInterval(this.alarmTimer);
      this.alarmTimer = null;
      this.$store.state.alarmStatus = "";
      this.$store.state.alarmPulseColor = "";
      // Clear all zones
      this.$store.state.zones[0] = "0";
      this.$store.state.zones[1] = "0";
      this.$store.state.zones[2] = "0";
      this.$store.state.zones[3] = "0";
      this.$store.state.zones[4] = "0";
      this.$store.state.zones[5] = "0";
      this.$store.state.zones[6] = "0";
      this.$store.state.zones[7] = "0";
      this.$store.state.zones[8] = "0";
      this.$store.state.zones[9] = "0";
      this.$store.state.zones[10] = "0";
      this.$store.state.zones[11] = "0";
      this.$store.state.zones[12] = "0";
      this.$store.state.zones[13] = "0";
      this.$store.state.zones[14] = "0";
      this.$store.state.zones[15] = "0";
      this.sortedEvents.every((e, index) => {
        if (
          val.IDO == e.IDO &&
          val.JLEIRAS == e.JLEIRAS &&
          val.LEIRAS == e.LEIRAS
        ) {
          this.eventAnalizer(this.sortedEvents.slice(index), false);
          return false;
        }
        return true;
      });
    },
    "$store.state.alarmStatus": function (val) {
      this.$store.state.smsInProgress = false;
      clearInterval(this.alarmTimer2);
    },
    "$store.state.smsInProgress": function (val) {
      if (val) {
        this.alarmDisarm();
      }
    },
    filterStatus: {
      handler: "runFilter",
      // immediate: true
    },

    searchField: {
      handler: "runSearchFilter",
    },
  },
  beforeDestroy() {
    clearInterval(this.alarmTimer);
    clearInterval(this.interval);
    clearInterval(this.alarmTimer2);
    clearInterval(this.smsTimer);
  },
};
</script>

<style>
input,
textarea {
  font-size: 16px;
}

h5 {
  text-align: center;
}

#no-background-hover::before {
  background-color: transparent !important;
}

.app_bar img {
  width: 30px;
  height: 30px;
}

a {
  text-decoration: none;
}

.emailContainer {
  height: 50px;
  align-content: center;
}

.v-list-item__title {
  height: 50px;
}

.lastStatus .v-btn__content {
  white-space: normal;
  flex: auto;
}

.lastStatus .v-btn {
  min-height: 52px;
  height: 100% !important;
}

/* Special rotation block  */

/* @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
  html {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
} */

/* @media screen and (min-aspect-ratio: 13/9) {
  html {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
  .bw-dashboard {
    height: inherit;
  }
}
@media screen and (min-width: 1292px) {
  html {
    transform: none;
    transform-origin: none;
    width: 100%;
    overflow-x: none;
    position: relative;
  }
  .bw-dashboard {
    height: 100vh;
  }
} */

/* turn off min-width for all buttons */
</style>
