<template>
  <v-container fluid>
    <!-- Snackbar -->
    <v-snackbar v-model="$store.state.snackbarTrigger" :timeout="3000">
      {{ $store.state.snackbarMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn color="cyan darken-2" text v-bind="attrs" @click="$store.commit('setSnackbarTrigger', false)">
          Ok
        </v-btn>
      </template>
    </v-snackbar>
    <v-btn rounded class="ml-n2 mt-n12" small @click.prevent="$store.state.currentPage = 'home'" v-if="isMobile">
      <v-icon size="20"> mdi-arrow-left-thick </v-icon>
    </v-btn>
    <!-- Desktop -->
    <v-row class="d-flex mb-2 mt-n8 mr-n6" align="center" justify="center" v-if="!isMobile">
      <v-btn class="d-flex rounded-xl" width="98%">
        <v-row class="d-flex" align="center" justify="center">
          <v-col cols="2" class="ml-n5">
            <h4>Számlaszám</h4>
          </v-col>
          <v-col cols="3">
            <h4>Kelt</h4>
          </v-col>
          <v-col cols="3">
            <h4>Érték</h4>
          </v-col>
          <v-col cols="3">
            <h4>Kiegy.érték</h4>
          </v-col>
        </v-row>
      </v-btn>
    </v-row>
    <!-- Mobile -->
    <v-row class="d-flex mb-2" align="center" justify="center" v-if="isMobile">
      <v-btn class="d-flex rounded-xl" width="100%">
        <v-row class="d-flex mr-1" align="center" justify="center">
          <v-col cols="2" class="mr-2">
            <h6>Sorszám</h6>
          </v-col>
          <v-col cols="3">
            <h6>Kelt</h6>
          </v-col>
          <v-col cols="3" class="mr-1">
            <h6>Érték</h6>
          </v-col>
          <v-col cols="3">
            <h6>Kiegy.érték</h6>
          </v-col>
        </v-row>
      </v-btn>
    </v-row>
    <div v-if="sortedReceipts != 'EMPTY'">
      <v-row class="d-flex mx-n6" align="center" justify="center" v-for="userReceipt, index in sortedReceipts"
        :key="userReceipt.ACCOUNT + userReceipt.LINE + index">
        <ReceiptsCard class="mb-1 mx-12" :userReceipt="userReceipt" v-if="!isMobile" />
        <ReceiptsCard class="mb-1" :userReceipt="userReceipt" v-if="isMobile" />
      </v-row>
    </div>
    <div v-else>
      <v-row align="center" justify="center" class="mt-4">
        <h4>Nincs megjeleníthető számla</h4>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";
import { isMobile } from "mobile-device-detect";
import ReceiptsCard from "./ReceiptsCard";

export default {
  name: "Events",
  components: {
    ReceiptsCard,
  },
  data() {
    return {
      isMobile: isMobile,
      receipts: [],
      date: null,
    };
  },
  created() {
    this.$store.state.wsOverlay = true;
    // Get all events for account
    axios.get(process.env.VUE_APP_API_URL + "/my_monitoring_api.php", {
      params: {
        uid: this.$store.state.user.TOKEN,
        eventType: 'getReceipts',
        account: this.$store.state.user.ACCOUNT,
        line: this.$store.state.user.LINE
      },
      withCredentials: true
    })
      .then(response => {
        if (typeof response.data === "object") {
          this.receipts = response.data;
        } else {
          this.receipts = "EMPTY";
        }
        this.$store.state.wsOverlay = false;
      })
      .catch((error) => {
        this.$store.state.wsOverlay = false;
        console.log(error);
        //Snackbar here
        this.$store.commit("setSnackbarTrigger", false);
        setTimeout(
          () =>
            this.delayedNotification(
              "Adatbázis kapcoslat sikertelen:(",
              3000,
              true
            ),
          100
        );
      });
  },
  computed: {
    sortedReceipts: {
      get: function () {
        if (this.receipts != "EMPTY") {
          return this.receipts.sort((a, b) => {
            if (a.SZAMLK < b.SZAMLK) {
              return 1;
            }
            if (a.SZAMLK > b.SZAMLK) {
              return -1;
            }
            return 0;
          });
        }
        else {
          return "EMPTY"
        }
      },
    },
  },
  methods: {
    delayedNotification(message, timeout, bool) {
      this.$store.commit('setSnackbarMessage', message)
      this.$store.commit('setSnackbarTimeout', timeout)
      this.$store.commit('setSnackbarTrigger', bool)
    },
  },
};
</script>

<style></style>
