import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    snackbarTrigger: false,
    snackbarMessage: "",
    snackbarTimeout: 3000,
    currentPage: 'home',
    statusCode: '00',
    errorStatusCode: '00',
    desktopScrollHeight: "0",
    mobileScrollHeight: "0",
    sortedEvents: null,
    alarm: false,
    alarmStatus: '',
    alarmPulseColor: '',
    smsInProgress: false,
    zones: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    alarmTimer: null,
    progress: 0,
    alarmTimer2: null,
    wsOverlay: false,
    smsTimer: null,
    events: [],
    interval: null,
    status: false,
    triggerGetEvents: false,
    event : {'IDO': "Lekérdezés alatt...", 'LEIRAS': 'Lekérdezés alatt...'},
    alarmSignals: ['PEA', 'ALR', 'ATM', 'BTS', 'CLE', 'CLL', 'DUR', 'FIR', 'GAS', 'HLA', 'HTP', 'JAR', 'LTP', 'MAN', 'MED', 'MOC', 'OPA', 'OPE', 'OPL', 'POL', 'PSZ', 'RER', 'RSZ', 'SMO', 'SZJ', 'TMJ', 'TSP', 'NSS', 'HLS', 'LIF', 'KIV', 'PLZ']
  },
  getters: {
  },
  mutations: {
    setSnackbarMessage(state, data) {
      state.snackbarMessage = data
    },
    setSnackbarTrigger(state, data){
      state.snackbarTrigger = data
    },
    setSnackbarTimeout(state, data){
      state.snackbarTimeout = data
    },
    setUser(state, data){
      state.user = data
    },
  },
  actions: {
  },
  modules: {
  }
})
