import Vue from 'vue'
import VueRouter from 'vue-router'
import HomePage from '@/components/home/HomePage'
import Login from '@/components/auth/Login'
import store from '@/store/store'
import Axios from 'axios' // Import Axios


Vue.use(VueRouter)
var guest = false

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage,
    props: true
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

//Route guards
// router.beforeEach((to, from, next) => {
//   if(to.path != "/login" && (store.state.user == null)) {
//     next({ name: 'Login'});
//   }
//   else {
//     next()
//   }
// })

router.beforeEach((to, from, next) => {
  if (to.path != "/login" && (store.state.user == null)) {
    Axios.get(process.env.VUE_APP_API_URL + "/check_session.php", { withCredentials: true })
      .then(response => {
        if (response.data.loggedIn) {
          store.state.user = response.data.userData;
          if (to.path === '') {
            next({ name: 'HomePage' }); // Redirect to HomePage if no specific route is requested
          } else {
            next(); // Proceed to the intended route
          }
        } else {
          Axios.get(process.env.VUE_APP_API_URL + "/logout.php", { withCredentials: true })
          // User is not logged in, redirect to login
          next({ name: 'Login' });
        }
      })
      .catch(error => {
        console.error('Error checking session:', error);
        // Handle the error appropriately
        next({ name: 'Login' });
      });
  }
  else {
    next()
  }
})


export default router
