<template>
  <v-container fluid>
    <v-expansion-panels v-model="panelStatus" focusable class="condensed rounded-xl mt-3">
      <v-expansion-panel class="my-n2">
        <v-expansion-panel-header
          class="rounded-xl"
          active-class="rounded-xl"
          expand-icon=""
        >
          <v-row
            class="d-flex align-center"
            align="center"
            justify="space-around"
          >
            <v-col
              class="d-flex align-center justify-space-around my-3"
              cols="2"
            >
              <h4 class="d-flex" v-if="!isMobile">
                {{ userReceipt.SZAMS }}
              </h4>
              <h5 class="d-flex" v-if="isMobile">
                {{ userReceipt.SZAMS }}
              </h5>
            </v-col>
            <v-col
              class="d-flex align-center justify-space-around my-3"
              cols="3"
            >
              <h4 class="d-flex" v-if="!isMobile">
                {{ userReceipt.SZAMLK }}
              </h4>
              <h5 class="d-flex" v-if="isMobile">
                {{ userReceipt.SZAMLK }}
              </h5>
            </v-col>
            <v-col
              class="d-flex align-center justify-space-around my-3"
              cols="3"
            >
              <h4 class="d-flex" v-if="!isMobile">
                {{ userReceipt.SZERT }} Ft
              </h4>
              <h5 class="d-flex" v-if="isMobile">{{ userReceipt.SZERT }} Ft</h5>
            </v-col>
            <v-col
              class="d-flex align-center justify-space-around my-3"
              cols="3"
            >
              <h4 class="d-flex" v-if="!isMobile">
                {{ userReceipt.KIEGYO }} Ft
              </h4>
              <h5 class="d-flex" v-if="isMobile">
                {{ userReceipt.KIEGYO }} Ft
              </h5>
            </v-col>
          </v-row>
          <template v-slot:actions>
            <v-btn class="mr-n6" icon @click.stop="onClick()">
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="d-flex" align="center" justify="center">
            <v-col class="d-flex align-center justify-center">
              <!-- Receipt Desktop -->
              <v-card
                color="grey lighten-2"
                elevation="6"
                rounded
                class="px-8 py-5 rounded-xl mt-6"
                v-if="!isMobile"
              >
                <v-row align="center" justify="center">
                  <v-col cols="11" class="d-flex align-center justify-center">
                    <v-card
                      color="grey lighten-3"
                      elevation="7"
                      class="mr-2 rounded-xl"
                    >
                      <h4 class="mx-4 my-2 text-center">
                        Számla adatok
                      </h4>
                    </v-card>
                  </v-col>
                  <!-- Left block -->
                  <v-col class="align-center justify-start" cols="6">
                    <v-row class="d-flex" align="center" justify="start">
                      <v-col class="d-flex align-center justify-start">
                        <v-card
                          color="grey lighten-3"
                          elevation="7"
                          class="mr-2 rounded-xl"
                        >
                          <h4 class="mx-4 my-2 text-center">Sorszám:</h4>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-spacer class="my-1"></v-spacer>
                    <v-row class="d-flex" align="center" justify="start">
                      <v-col class="d-flex align-center justify-start">
                        <v-card
                          color="grey lighten-3"
                          elevation="7"
                          class="mr-2 rounded-xl"
                        >
                          <h4 class="mx-4 my-2 text-center">Kelt:</h4>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-spacer class="my-1"></v-spacer>
                    <v-row class="d-flex" align="center" justify="start">
                      <v-col class="d-flex align-center justify-start">
                        <v-card
                          color="grey lighten-3"
                          elevation="7"
                          class="mr-2 rounded-xl"
                        >
                          <h4 class="mx-4 my-2 text-center">Teljesítés:</h4>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-spacer class="my-1"></v-spacer>
                    <v-row class="d-flex" align="center" justify="start">
                      <v-col class="d-flex align-center justify-start">
                        <v-card
                          color="grey lighten-3"
                          elevation="7"
                          class="mr-2 rounded-xl"
                        >
                          <h4 class="mx-4 my-2 text-center">Esedékesség:</h4>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-spacer class="my-1"></v-spacer>
                    <v-row class="d-flex" align="center" justify="start">
                      <v-col class="d-flex align-center justify-start">
                        <v-card
                          color="grey lighten-3"
                          elevation="7"
                          class="mr-2 rounded-xl"
                        >
                          <h4 class="mx-4 my-2 text-center">Érték:</h4>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-spacer class="my-1"></v-spacer>
                    <v-row class="d-flex" align="center" justify="start">
                      <v-col class="d-flex align-center justify-start">
                        <v-card
                          color="grey lighten-3"
                          elevation="7"
                          class="mr-2 rounded-xl"
                        >
                          <h4 class="mx-4 my-2 text-center">Kiegyenlített:</h4>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col>
                  <!-- Right block -->
                  <v-col class="align-center justify-start" cols="5">
                    <v-row class="d-flex" align="center" justify="start">
                      <v-col class="d-flex align-center justify-start">
                        <h4
                          class="mx-2 my-2 text-center"
                          v-if="userReceipt.SZAMS"
                        >
                          {{ userReceipt.SZAMS }}
                        </h4>
                        <h4 class="mx-2 my-2 text-center" v-else>Nincs adat</h4>
                      </v-col>
                    </v-row>
                    <v-divider class="my-2"></v-divider>
                    <v-row class="d-flex" align="center" justify="start">
                      <v-col class="d-flex align-center justify-start">
                        <h4
                          class="mx-2 my-2 text-center"
                          v-if="userReceipt.SZAMLK"
                        >
                          {{ userReceipt.SZAMLK }}
                        </h4>
                        <h4 class="mx-2 my-2 text-center" v-else>Nincs adat</h4>
                      </v-col>
                    </v-row>
                    <v-divider class="my-2"></v-divider>
                    <v-row class="d-flex" align="center" justify="start">
                      <v-col class="d-flex align-center justify-start">
                        <h4
                          class="mx-2 my-2 text-center"
                          v-if="userReceipt.TKELT"
                        >
                          {{ userReceipt.TKELT }}
                        </h4>
                        <h4 class="mx-2 my-2 text-center" v-else>Nincs adat</h4>
                      </v-col>
                    </v-row>
                    <v-divider class="my-2"></v-divider>
                    <v-row class="d-flex" align="center" justify="start">
                      <v-col class="d-flex align-center justify-start">
                        <h4
                          class="mx-2 my-2 text-center"
                          v-if="userReceipt.ESEDEKES"
                        >
                          {{ userReceipt.ESEDEKES }}
                        </h4>
                        <h4 class="mx-2 my-2 text-center" v-else>Nincs adat</h4>
                      </v-col>
                    </v-row>
                    <v-divider class="my-2"></v-divider>
                    <v-row class="d-flex" align="center" justify="start">
                      <v-col class="d-flex align-center justify-start">
                        <h4
                          class="mx-2 my-2 text-center"
                          v-if="userReceipt.SZERT"
                        >
                          {{ userReceipt.SZERT }} Ft
                        </h4>
                        <h4 class="mx-2 my-2 text-center" v-else>Nincs adat</h4>
                      </v-col>
                    </v-row>
                    <v-divider class="my-2"></v-divider>
                    <v-row class="d-flex" align="center" justify="start">
                      <v-col class="d-flex align-center justify-start">
                        <h4
                          class="mx-2 my-2 text-center"
                          v-if="userReceipt.KIEGYO"
                        >
                          {{ userReceipt.KIEGYO }} Ft
                        </h4>
                        <h4 class="mx-2 my-2 text-center" v-else>Nincs adat</h4>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>

              <!-- Receipt Mobile -->
              <v-card
                color="grey lighten-2"
                elevation="6"
                rounded
                class="pl-2 pr-2 py-5 rounded-xl mt-6"
                v-if="isMobile"
              >
                <v-row align="center" justify="center">
                  <v-col cols="11" class="d-flex align-center justify-center">
                    <v-card
                      color="grey lighten-3"
                      elevation="7"
                      class="mr-2 rounded-xl"
                    >
                      <h5 class="mx-4 my-2 text-center">
                        Számla adatok
                      </h5>
                    </v-card>
                  </v-col>
                  <!-- Left block -->
                  <v-col class="align-center justify-start" cols="7">
                    <v-row class="d-flex" align="center" justify="start">
                      <v-col class="d-flex align-center justify-start">
                        <v-card
                          color="grey lighten-3"
                          elevation="7"
                          class="mr-2 rounded-xl"
                        >
                          <h5 class="mx-2 my-2 text-center">Sorszám:</h5>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-spacer class="my-1"></v-spacer>
                    <v-row class="d-flex" align="center" justify="start">
                      <v-col class="d-flex align-center justify-start">
                        <v-card
                          color="grey lighten-3"
                          elevation="7"
                          class="mr-2 rounded-xl"
                        >
                          <h5 class="mx-2 my-2 text-center">Kelt:</h5>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-spacer class="my-1"></v-spacer>
                    <v-row class="d-flex" align="center" justify="start">
                      <v-col class="d-flex align-center justify-start">
                        <v-card
                          color="grey lighten-3"
                          elevation="7"
                          class="mr-2 rounded-xl"
                        >
                          <h5 class="mx-2 my-2 text-center">Teljesítés:</h5>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-spacer class="my-1"></v-spacer>
                    <v-row class="d-flex" align="center" justify="start">
                      <v-col class="d-flex align-center justify-start">
                        <v-card
                          color="grey lighten-3"
                          elevation="7"
                          class="mr-2 rounded-xl"
                        >
                          <h5 class="mx-2 my-2 text-center">Esedékesség:</h5>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-spacer class="my-1"></v-spacer>
                    <v-row class="d-flex" align="center" justify="start">
                      <v-col class="d-flex align-center justify-start">
                        <v-card
                          color="grey lighten-3"
                          elevation="7"
                          class="mr-2 rounded-xl"
                        >
                          <h5 class="mx-2 my-2 text-center">Érték:</h5>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-spacer class="my-1"></v-spacer>
                    <v-row class="d-flex" align="center" justify="start">
                      <v-col class="d-flex align-center justify-start">
                        <v-card
                          color="grey lighten-3"
                          elevation="7"
                          class="mr-2 rounded-xl"
                        >
                          <h5 class="mx-2 my-2 text-center">Kiegyenlített:</h5>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col>
                  <!-- Right block -->
                  <v-col class="align-center justify-start" cols="5">
                    <v-row class="d-flex" align="center" justify="start">
                      <v-col class="d-flex align-center justify-start">
                        <h5
                          class="my-2 text-center"
                          v-if="userReceipt.SZAMS"
                        >
                          {{ userReceipt.SZAMS }}
                        </h5>
                        <h5 class="my-2 text-center" v-else>Nincs adat</h5>
                      </v-col>
                    </v-row>
                    <v-divider class="my-2"></v-divider>
                    <v-row class="d-flex" align="center" justify="start">
                      <v-col class="d-flex align-center justify-start">
                        <h5
                          class="my-2 text-center"
                          v-if="userReceipt.SZAMLK"
                        >
                          {{ userReceipt.SZAMLK }}
                        </h5>
                        <h5 class="my-2 text-center" v-else>Nincs adat</h5>
                      </v-col>
                    </v-row>
                    <v-divider class="my-2"></v-divider>
                    <v-row class="d-flex" align="center" justify="start">
                      <v-col class="d-flex align-center justify-start">
                        <h5
                          class=" my-2 text-center"
                          v-if="userReceipt.TKELT"
                        >
                          {{ userReceipt.TKELT }}
                        </h5>
                        <h5 class=" my-2 text-center" v-else>Nincs adat</h5>
                      </v-col>
                    </v-row>
                    <v-divider class="my-2"></v-divider>
                    <v-row class="d-flex" align="center" justify="start">
                      <v-col class="d-flex align-center justify-start">
                        <h5
                          class=" my-2 text-center"
                          v-if="userReceipt.ESEDEKES"
                        >
                          {{ userReceipt.ESEDEKES }}
                        </h5>
                        <h5 class=" my-2 text-center" v-else>Nincs adat</h5>
                      </v-col>
                    </v-row>
                    <v-divider class="my-2"></v-divider>
                    <v-row class="d-flex" align="center" justify="start">
                      <v-col class="d-flex align-center justify-start">
                        <h5
                          class=" my-2 text-center"
                          v-if="userReceipt.SZERT"
                        >
                          {{ userReceipt.SZERT }} Ft
                        </h5>
                        <h5 class=" my-2 text-center" v-else>Nincs adat</h5>
                      </v-col>
                    </v-row>
                    <v-divider class="my-2"></v-divider>
                    <v-row class="d-flex" align="center" justify="start">
                      <v-col class="d-flex align-center justify-start">
                        <h5
                          class=" my-2 text-center"
                          v-if="userReceipt.KIEGYO"
                        >
                          {{ userReceipt.KIEGYO }} Ft
                        </h5>
                        <h5 class=" my-2 text-center" v-else>Nincs adat</h5>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <!-- Dekstop -->
    <!-- <v-card class="mb-1 mx-1 rounded-xl" v-if="!isMobile">
        <v-row class="d-flex mr-2" align="center" justify="center">
          <v-col class="d-flex align-center justify-center" cols="2">
                <h4>
              {{ userReceipt.SZAMS}}
            </h4>            
          </v-col>
          <v-col class="d-flex align-center justify-center" cols="3">
            <h4>
              {{ userReceipt.SZAMLK }}
            </h4>
          </v-col>
          <v-col class="d-flex align-center justify-center" cols="3">
            <h4>
              {{ userReceipt.SZERT}} Ft
            </h4>
          </v-col>
          <v-col class="d-flex align-center justify-center" cols="3">
            <h4>
              {{ userReceipt.KIEGYO}} Ft
            </h4>
          </v-col>
        </v-row>
      </v-card> -->
    <!-- Mobile -->
    <!-- <v-card class="mb-1 rounded-xl" v-if="isMobile">
        <v-row class="d-flex" align="center" justify="center">
          <v-col class="d-flex align-center justify-center" cols="2">
            <h6>
              {{ userReceipt.SZAMS}}
            </h6>            
          </v-col>
          <v-col class="d-flex align-center justify-center" cols="3">
            <h6>
              {{ userReceipt.SZAMLK }}
            </h6>
          </v-col>
          <v-col class="d-flex align-center justify-center" cols="3">
            <h6>
              {{ userReceipt.SZERT}} Ft
            </h6>
          </v-col>
          <v-col class="d-flex align-center justify-center" cols="3">
            <h6>
              {{ userReceipt.KIEGYO}} Ft
            </h6>
          </v-col>
        </v-row>
      </v-card> -->
  </v-container>
</template>

<script>
import { isMobile } from "mobile-device-detect";

export default {
  name: "ReceiptCard",
  props: ["userReceipt"],
  data() {
    return {
      isMobile: isMobile,
      panelStatus: undefined,
    };
  },
  methods: {
    onClick() {
      const curr = this.panelStatus;
      this.panelStatus = curr === undefined ? 0 : undefined;
    },
  },
  components: {},
};
</script>

<style scoped>
.thick-divider {
    height: 12px; /* Adjust the thickness as needed */
  }
</style>
